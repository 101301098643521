<template>
  <div>
    <div v-if="pageReady" class="d-flex justify-content-center">
      <div class="container mx-5 px-5 h-100">
        <div id="tpd-lawyer-only-navbar" class="row d-flex mx-auto">
          <ul
            id="tpd-pills-tab"
            class="nav nav-pills d-flex tpd-tabs justify-content-between flex-wrap p-0 overflow-hidden mx-auto"
          >
            <li
              v-for="item in renderTabs"
              :key="item.title"
              class="nav-item mb-3"
            >
              <router-link
                :to="{
                  name: `${item.title}`,
                  params: { id: $route.params.id },
                }"
              >
                <button
                  type="button"
                  class="btn nav-link tpd-lawyer-nav bold text-capitalize"
                >
                  {{ item.display_title }}
                </button>
              </router-link>
            </li>
          </ul>
        </div>
        <div class="container-fluid mt-5 overflow-auto bg-white shadow">
          <router-view></router-view>
        </div>
      </div>
    </div>
    <div
      v-else
      class="min-vh-100 d-flex justify-content-center align-items-center"
    >
      <div>
        <div
          class="spinner-border text-primary"
          style="width: 12rem; height: 12rem"
          role="status"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NonLawyerTemplateBuilding',
  components: {},

  data() {
    return {
      pageReady: false,

      renderTabs: [
        {
          title: 'tpd-completed',
          display_title: 'TPD Completed',
        },
      ],

      //   {
      //     title: 'tpd-admin',
      //     display_title: 'Admin',
      //   },

      //   { title: 'tpd-nurse', display_title: 'Nurse' },

      //   { title: 'tpd-doctor', display_title: 'Doctor TPD' },
      //   {
      //     title: 'tpd-specialist',
      //     display_title: 'Specialist TPD',
      //   },
    }
  },

  computed: {},

  async mounted() {
    await this.getAsssesment()

    if (this.$store.state.profile) {
      let profile = this.$store.state.profile

      //retrieve service type
      //check if specialist is included in this selection
      let specialist_included =
        this.$store.state.TPDAssessment.referral.service_requirement_choices.find(
          (x) => {
            return (
              x.service_type ===
              this.$store.state.TPDAssessment.referral.service_requirement
                ?.service_type
            )
          }
        ).include_specialist

      let nurse_included =
        this.$store.state.TPDAssessment.referral.service_requirement_choices.find(
          (x) => {
            return (
              x.service_type ===
              this.$store.state.TPDAssessment.referral.service_requirement
                ?.service_type
            )
          }
        ).include_nurse

      // Check for doctor / specialist user
      if (profile.is_specialist || profile.is_doctor) {
        // Check for specialist included in TPD
        if (specialist_included) {
          // Add specialist tabs
          this.renderTabs.unshift({
            title: 'tpd-specialist',
            display_title: 'Specialist TPD',
          })
        }
        // Add remaining doctor / specialist tabs
        this.renderTabs.unshift({
          title: 'tpd-doctor',
          display_title: 'Doctor TPD',
        })
      }
      // Check and add nurse tab
      if (
        (profile.is_nurse || profile.is_specialist || profile.is_doctor) &&
        nurse_included
      ) {
        this.renderTabs.unshift({ title: 'tpd-nurse', display_title: 'Nurse' })
      }

      // Admin only allowed for booking but some nurse can do both roles
      if (
        profile.is_admin ||
        profile.is_nurse ||
        profile.is_specialist ||
        profile.is_doctor
      ) {
        this.renderTabs.unshift({
          title: 'tpd-admin',
          display_title: 'Admin',
        })
      }
    }
    await this.getTPDTemplates()

    this.pageReady = true
  },

  methods: {
    async getAsssesment() {
      await this.$store
        .dispatch('getTPDAssessment', { id: this.$route.params.id })

        .then((response) => {
          this.$store.state.TPDAssessment = response.data
        })
        .catch((error) => {
          console.log(error)
        })
    },

    async getTPDTemplates() {
      await this.$store
        .dispatch('getTPDTemplates')
        .then((response) => {
          //store to vuex

          this.$store.state.TPDTemplates = response.data
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.dropdown-menu {
  z-index: 999;
}

.tpd-tabs {
  max-width: 1400px;
  .nav-item {
    position: relative;

    .router-link-exact-active {
      .tpd-lawyer-nav {
        background-color: #32c3d6;
        color: white;
      }
    }

    &:not(:last-child) {
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        border-top: 2px solid #32c3d6;
        background: none;
        width: 675%;
        max-width: 550px;
        z-index: -999;
        transform: translateY(-50%);
      }

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        border-top: 2px solid #32c3d6;
        background: none;
        width: 575%;
        max-width: 500px;
        z-index: -999;
        transform: translateY(-50%);
      }
    }

    &:last-child {
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        border-top: 2px solid #32c3d6;
        background: none;
        width: 800%;
        max-width: 700px;
        z-index: -999;
        transform: translateY(-50%);
      }

      .tpd-lawyer-nav {
        float: right;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          top: 33%;
          left: -4%;
          width: 0;
          height: 0;
          border-top: 6px solid transparent;
          border-bottom: 6px solid transparent;

          border-left: 7px solid #32c3d6;
        }
      }
    }

    &:not(:first-child) {
      margin-left: 20%;
    }

    .tpd-lawyer-nav {
      border: 1px solid #32c3d6;
      border-radius: 0;
      background-color: white;

      &:disabled {
        background-color: gray;
        border: none;
        opacity: 100;
        color: white;
        pointer-events: none;
        cursor: not-allowed;
      }
    }
  }
}
</style>
